import React, { Component } from 'react'
import styled from "styled-components";
import {NavLink} from 'react-router-dom'

export class NavItemComponent extends Component {
  render() {
    return (
      <NavItem to= {this.props.to}>{this.props.children}</NavItem>
    )
  }
}

export const Navbar = styled.div`
  height: 8vh;
  background-color: var(--less-white);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--theme-color);
  padding: 0px 30px;
  border-bottom: 1px solid #ddd;
  
`;
export const Logo = styled(NavLink)`
  font-family: "Staatliches", cursive;
  font-size: 1.8em;
  color: var(--theme-color);
  text-decoration: none;
`;

export const NavLinks = styled.ul`
  font-family: "Montserrat", sans-serif;
  list-style: none;
  z-index: 3;
  display: flex;
  flex-direction: row;
  width: 40%;
  justify-content: space-around;
  font-size: 0.9em;
  font-weight: bold;
  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 100%;
    }
  }

  @media screen and (max-width: 1080px) {
    width: 50%;
  }

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: var(--less-white);
    align-items: center;
    position: absolute;
    right: 0;
    height: 92vh;
    width: 50vw;
    top: 8vh;
    transform: ${props => (props.show ? "translateX(0)" : "translateX(100%)")};
    transition: transform 0.2s ease-in;
  }
`;

export const NavItem = styled(NavLink)`
  text-decoration: none;
  color: var(--theme-color);
  min-height: 8vh;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const Hamburger = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    height: 12px;
    border-radius: 5px;
  }
`;

export const Layer = styled.div`
  background-color: var(--theme-color);
  border-radius: 10px;
  height: 2px;
  width: 18px;
`;
