import React from 'react'
import {Button, HeroButtonHolder} from './Common'
import {Link} from 'react-router-dom'




export default function FrontPageButtons() {
    return (
        <HeroButtonHolder>
          
          <Link to = "research"><Button>Research</Button></Link>
          <Link to = "transcription"><Button>Transcription</Button></Link>
          
        </HeroButtonHolder>
    )
}
