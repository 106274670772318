import React, { Component } from "react";
import { Link } from "react-router-dom";
import {withFirebase} from '../../Firebase'

class RegisterBase extends Component {
  state = {
    email: "",
    password: "",
    password2: ""
  };

  render() {
    
    return (
      <div className="Auth">
        <form onSubmit={this.onSubmit}>
          <p className="title">Sign up and track your jobs.</p>
          <input
            type="email"
            name="email"
            placeholder="Email"
            onChange={this.onChange}
          />
          <input
            type="password"
            name="password"
            placeholder="Password"
            onChange={this.onChange}
          />
          <input
            type="password"
            name="password2"
            placeholder="Confirm Password"
            onChange={this.onChange}
          />
          <button type="submit">Register</button>
        </form>

        <p>
          Already have an account? <Link to="/login">Log in</Link>
        </p>
      </div>
    );
  }

  onSubmit = e => {
    e.preventDefault();
    console.log("Submit");
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
    console.log(this.state);
  };
}

const Register = withFirebase(RegisterBase);

export default Register;
