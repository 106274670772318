import React from "react";
import "./App.css";
import {
  Navbar,
  Logo,
  NavLinks,
  NavItemComponent,
  Hamburger,
  Layer,
} from "./components/assets/nav/NavItems";
import HeroTemplate from "./components/pages/HeroTemplate";
import FrontPageButtons from "./components/assets/FrontPageButtons";
import Login from "./components/auth/Login";
import Register from "./components/auth/Register";
import Transcription from "./components/pages/Transcription";
import Research from "./components/pages/Research";
import Pricing from "./components/pages/Pricing";
import { Route, HashRouter as Router, Switch } from "react-router-dom";
import img from "./assets/laptop.jpg";
import { Success, Fail } from "./components/pages/PaymentResult";
import Nav from "./components/Nav";



class App extends React.Component {
  state = {
    showNav: false,
  };

  toggleNavDrawer = (props) => {
    this.setState({ showNav: !this.state.showNav });
    console.log(this.state.showNav);
  };

  closeNavDrawer = () => {
    if (this.state.showNav) {
      this.setState({ showNav: false });
    }
  };

  render() {
    return (
     
        <div onClick={this.closeNavDrawer}>
          <Router>
              <Nav></Nav>
              <div className="all-container">
                <Route
                  path="/"
                  exact
                  render={() => {
                    return (
                      <HeroTemplate
                        title="Your life. Simplified."
                        subtitle="Pick a service to get started."
                        backgroundImagePath={img}
                      >
                        <FrontPageButtons />
                      </HeroTemplate>
                    );
                  }}
                />
                <Switch>
                  <Route path="/research" exact component={Research} />
                  <Route
                    path="/transcription"
                    exact
                    component={Transcription}
                  />
                  <Route path="/success" exact component={Success} />
                  <Route path="/fail" exact component={Fail} />
                  <Route path="/pricing" exact component={Pricing} />
                  <Route path="/login" exact component={Login} />
                  <Route path="/register" exact component={Register} />
                </Switch>
              </div>
          </Router>
        </div>

    );
  }
}

export default App;

{
  /* <Navbar>
              <Logo to="/">Vanguard</Logo>
              <NavLinks
                show={this.state.showNav}
                id="navLinks"
                onClick={this.closeNavDrawer}
              >
                <NavItemComponent to="/research" onClick={this.closeNavDrawer}>
                  Research
                </NavItemComponent>
                <NavItemComponent
                  to="/transcription"
                  onClick={this.closeNavDrawer}
                >
                  Transcription
                </NavItemComponent>

                <NavItemComponent to="/pricing" onClick={this.closeNavDrawer}>
                  Pricing
                </NavItemComponent>

                <NavItemComponent to="/login" onClick={this.closeNavDrawer}>
                  Log In
                </NavItemComponent>
                <NavItemComponent to="/register" onClick={this.closeNavDrawer}>
                  Register
                </NavItemComponent>
              </NavLinks>
              <Hamburger onClick={this.toggleNavDrawer}>
                <Layer />
                <Layer />
                <Layer />
              </Hamburger>
            </Navbar> */
}
