import React, { Component } from "react";
import styled from "styled-components";

//TO DISABLE HORIZONTAL SCROLL, DO:
// html, body{
//   overflow-x: hidden;
//   max-width: 100%;
// }

// .App{
//   position: relative;
//   min-height: 100vh;
// }

export class Nav extends Component {
  navTop = styled.nav`
    position: fixed;
    margin-top: -8vh;
    z-index: 99;
    width: 100vw;
    height: 8vh;
    background-color: whitesmoke;
    padding: 0px 60px;
    color: var(--theme-color);
    display: flex;
    flex-direction: vertical;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

    /* The class names ending with Nav Out are for the nav drawer mechanism. */

    #nav-icon-background {
      padding: 10px;
      background-color: white;
      width: 45px;
      height: 40px;
      border-radius: 12px;
      cursor: pointer;
    }

    a {
      color: inherit;
      text-decoration: inherit;
    }

    #nav-icon {
      width: 100%;
      height: 100%;
      position: relative;
      transform: rotate(0deg);
      transition: 0.5s ease-in-out;
      background-color: #ffffff;
    }

    #nav-icon span {
      display: block;
      position: absolute;
      height: 3px;
      width: 100%;
      background: var(--theme-color);
      border-radius: 10px;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: 0.5s ease-in-out;
    }

    #nav-icon span:nth-child(1) {
      top: 0px;
    }

    #nav-icon span:nth-child(2) {
      top: 40%;
    }

    #nav-icon span:nth-child(3) {
      top: 80%;
    }

    #nav-icon.open span:nth-child(1) {
      top: 50%;
      transform: rotate(135deg);
    }

    #nav-icon.open span:nth-child(2) {
      opacity: 0;
      left: -60px;
    }

    #nav-icon.open span:nth-child(3) {
      top: 50%;
      transform: rotate(-135deg);
    }

    #navList.open {
      transform: translateX(-100vw);
    }

    /* The hamburger div */
    > div {
      padding: 0px 10px;
      height: 70%;
      z-index: 2;
      display: none;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      transition: all 0.5s ease-in;
    }

    h1 {
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: 'Fira Sans', sans-serif;
      height: 100%;
      vertical-align: middle;
      cursor: pointer;
    }

    ul#navList {
      text-decoration: "none";
      list-style-type: none;
      height: 100%;
      transition: all 0.5s ease-in;

      

      li {
        float: left;
        display: flex;
        cursor: pointer;
        justify-content: center;
        align-items: center;
        padding: 0px 10px;
        height: 100%;
        &:hover {
          background-color: var(--theme-color);
          color: whitesmoke;
        }
      }
    }

    @media screen and (max-width: 768px) {
      padding: 0px 30px;

      > ul#navList {
        position: absolute;
        z-index: 1;
        display: block;
        left: 100vw;
        top: 0vh;
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;
        background-color: var(--theme-color);
        font-weight: bold;

        li {
          width: 100%;
          height: 100%;
          &:hover {
            color: #ddfff7;
          }
        }

        a {
          width: 50%;
          height: 15%;
        }
      }

      /* The hamburger div */
      > div {
        display: flex;
      }
    }
  `;

  navDrawn = false;

  toggleNav = () => {
    if (document.documentElement.clientWidth < 1000) {
      console.log(document.documentElement.clientWidth);
      document.getElementById("nav-icon").classList.toggle("open");
      document.getElementById("navList").classList.toggle("open");
    }
  };

  render() {
    return (
      <this.navTop>
        <a href="/">
          <h1>Vanguard</h1>
        </a>
        <ul id="navList">
          <a href="#/research">
            <li onClick={this.toggleNav}>Research</li>
          </a>
          <a href="#/transcription">
            <li onClick={this.toggleNav}>Transcription</li>
          </a>
          <a href="#/pricing">
            <li onClick={this.toggleNav}>Pricing</li>
          </a>
          {/* <a href="#/login">
            <li onClick={this.toggleNav}>Log In</li>
          </a>
          <a href="#/register">
            <li onClick={this.toggleNav}>Sign up</li>
          </a> */}
          {/* <a href="#/about"><li onClick={this.toggleNav}>About Us</li></a>
          <a href="#/portfolio"><li onClick={this.toggleNav}>Portfolio</li></a>*/}
        </ul>
        <div id="nav-icon-background" onClick={this.toggleNav}>
          <div id="nav-icon">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </this.navTop>
    );
  }
}

export default Nav;
