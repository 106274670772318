import React, { Component } from "react";
import {FlexDiv, Button } from "../assets/Common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle
} from "@fortawesome/free-solid-svg-icons";
import {Link} from 'react-router-dom'

export class Success extends Component {
  state = {
    redirect: false
  };
  render() {
    return (
      <FlexDiv style={{ height: "90vh" }}>
        <FontAwesomeIcon
          style={{ fontSize: "10em", color: "green" }}
          icon={faCheckCircle}
        />
        <h3 style={{ marginTop: "30px" }}>The transaction was successful.</h3>
        <Link to="/"><Button style={{ marginTop: "30px" }}>Return Home</Button></Link>
      </FlexDiv>
    );
  }
}

export class Fail extends Component {
  state = {
    redirect: false
  };
  render() {
    return (
      <FlexDiv style={{ height: "90vh" }}>
        <FontAwesomeIcon
          style={{ fontSize: "10em", color: "var(--theme-color)" }}
          icon={faTimesCircle}
        />
        <h3 style={{ marginTop: "30px" }}>
          Sorry, the transaction was unsuccessful.
        </h3>
        <Link to="/transcription"><Button style={{ marginTop: "30px" }}>Try again</Button></Link>
      </FlexDiv>
    );
  }
}
