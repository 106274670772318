import React, { Component } from "react";
// import {Button} from '../assets/Common'
import { Link } from "react-router-dom";
import { withFirebase } from "../../Firebase";

export class LoginBase extends Component {
  state = {
    email: "",
    password: "",
  };

  render() {
    return (
      <div className="Auth">
        <form onSubmit={this.onSubmit}>
          <p className="title">Log in and see your jobs.</p>
          <input type="email" placeholder="Email" onChange={this.onChange} />
          <input
            type="password"
            placeholder="Password"
            onChange={this.onChange}
          />
          <button type="submit" onChange={this.onChange}>
            Log In
          </button>
        </form>

        <p>
          Don't have an account? <Link to="/register">Sign up</Link>
        </p>
      </div>
    );
  }

  onSubmit = (e) => {
    e.preventDefault();
    console.log("Submit");
    const credentials = {
      username: this.state.email,
    };
  };

  onChange = (e) => {
    this.setState({ ...this.state, [e.target.name]: e.target.value });
    console.log(this.state);
  };
}

const Login = withFirebase(LoginBase);

export default Login;
