import styled from "styled-components";
import React, { Component } from "react";

export const scrollToElement = ref => {
  window.scrollTo(0, ref.current.offsetTop);
  //console.log(ref.current.offsetTop);
};

export const Button = styled.button`
  font-family: "Montserrat", sans-serif;
  background-color: var(--less-white);
  border-radius: 30px;
  border: 2px solid var(--theme-color);
  color: var(--theme-color);
  height: 40px;
  margin: 5px;
  width: 200px;
  transition: background-color 0.5s, color 0.2s;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
  outline: none;

  &:hover {
    color: var(--less-white);
    background-color: var(--theme-color);
  }
`;

export const FullButton = styled(Button)`
  color: var(--less-white);
  background-color: var(--theme-color);

  &:hover {
    background-color: var(--theme-color-dark);
    border-color: var(--theme-color-dark);
  }
`;

export const FlexDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Hr = styled.hr`
  display: block;
  border: 2px solid var(--theme-color);
  width: 60px;
  align-self: center;
  margin-top: 0px;
  margin-bottom: 50px;
  border-radius: 4px;
`;

export const Img = styled.img`
  width: 100%;
  height: auto;
  justify-self: center;
  border-radius: 5px;
  margin-bottom: 20px;
  min-width: 300px;
`;

/* This contains the image and any text that describes it.*/

export const ImgContainer = styled.div`
  margin-bottom: 30px;
`;

export const HeroButtonHolder = styled.div`
  text-align: center;
  display: flex;
  align-self: center;
  flex-direction: row;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

//SECTION*********************************************************************************************

const StyledSection = styled.section`
  width: 100%;
  min-height: 500px;
  height: auto;
  padding: 50px 150px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    min-height: 800px;
  }
  @media screen and (max-width: 1024px) {
    padding: 50px;
  }

  &:nth-child(even) {
    background-color: #eee;
  }

  @media screen and (max-width: 768px) {
    padding: 50px;
  }
`;

const SectionHeadings = styled.div`
  text-align: center;
  font-family: "Rufina", serif;
`;

const SectionTitle = styled.h1`
  margin-bottom: 15px;
  font-size: 30px;
  font-weight: normal;
`;

const SectionSubtitle = styled.p``;

const SectionContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export class Section extends Component {
  render() {
    return (
      <React.Fragment>
        <StyledSection id={this.props.id}>
          <SectionHeadings>
            <SectionTitle> {this.props.title}</SectionTitle>
            <SectionSubtitle
              style={{ display: this.props.subtitle ? "" : "none" }}
            >
              {" "}
              {this.props.subtitle}{" "}
            </SectionSubtitle>
          </SectionHeadings>
          <Hr style={{ display: this.props.title ? "" : "none" }} />
          <SectionContent>{this.props.children}</SectionContent>
        </StyledSection>
      </React.Fragment>
    );
  }
}

//************************************************************************************************

//FORM********************************************************************************************

//FOOTER*********************************************************************************************

export const Footer = styled.footer`
  display: flex;
  background-color: #ddd;
  width: 100%;
  text-align: center;
  font-size: 14px;
  justify-content: center;
  padding: 30px;
`;
//************************************************************************************************
