import React, { Component } from "react";
import styled from "styled-components";
import { Footer } from "../assets/Common";
import ScrollToTop from "../assets/ScrollToTop";

const Div = styled.div`
  overflow-x: auto;
  padding: 0 25% 10% 25%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 768px) {
    padding: 20px;
  }
`;
const Table = styled.table`
  margin: 0 40px 40px 40px;
`;

export default class Pricing extends Component {
  componentDidMount() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto"
    });
  }

  render() {
    return (
      <React.Fragment>
        <ScrollToTop />
        <Div>
          <h2>Essay Rubric (Local)</h2>
          <Table>
            <tr>
              <th>Chapter</th>
              <th>Per-Page Amount (USD)</th>
            </tr>
            <tr>
              <td>Undergraduate</td>
              <td>10.0</td>
            </tr>
            <tr>
              <td>Master's</td>
              <td>15.0</td>
            </tr>
            <tr>
              <td>Doctoral and Post-Doctoral</td>
              <td>20.0</td>
            </tr>
          </Table>
          <p>
            All essay samples are written in accordance with APA specifications
            with requests for different referencing styles to be specified by
            the client. A single page thus contains 275 words on average.
            Payments are to be made upon the onset of the agreement with the
            stipulated delivery time agreed upon beforehand. Expedited orders
            (less than 48-hour delivery period) will be charged at an additional
            25% additional fee to account for internal reallocation of
            resources.
          </p>

          <h2>Standard Charges</h2>
          <Table>
            <tr>
              <th>Chapter</th>
              <th>Amount (Ksh)</th>
            </tr>
            <tr>
              <td>Chapter 1 - Introduction</td>
              <td>12,500</td>
            </tr>
            <tr>
              <td>Chapter 2 - Literature Review</td>
              <td>25,000</td>
            </tr>
            <tr>
              <td>Chapter 3 - Methodology</td>
              <td>12,500</td>
            </tr>
            <tr>
              <td>Chapter 4 - Analysis and Presentation</td>
              <td>25,000</td>
            </tr>
            <tr>
              <td>Chapter 5 – Discussion, Conclusion and Recommendations</td>
              <td>15,000</td>
            </tr>
          </Table>
          <h3>Nature of Payment</h3>
          <p>
            Data collection charges are to be agreed upon following
            determination of the logistics involved. To activate a contract and
            initiate work, a payment of 25% of the total amount is to be sent to
            Vanguard Solution by the service seeker; this will serve as the
            deposit for the work to be completed. The amount is to be considered
            in offsetting payments by milestone, such that further payments will
            only be forthcoming upon exhaustion of the earlier deposited amount.
          </p>
          <h2>Standard Charges - Ph.D.</h2>
          <Table>
            <tr>
              <th>Chapter</th>
              <th>Amount (Ksh)</th>
            </tr>
            <tr>
              <td>Chapter 1 - Introduction</td>
              <td>18,750</td>
            </tr>
            <tr>
              <td>Chapter 2 - Literature Review</td>
              <td>37,500</td>
            </tr>
            <tr>
              <td>Chapter 3 - Methodology</td>
              <td>18,750</td>
            </tr>
            <tr>
              <td>Chapter 4 - Analysis and Presentation</td>
              <td>37,500</td>
            </tr>
            <tr>
              <td>Chapter 5 – Discussion, Conclusion and Recommendations</td>
              <td>22,500</td>
            </tr>
          </Table>
          <h3>Nature of Payment</h3>
          <p>
            Data collection charges are to be agreed upon following
            determination of the logistics involved. To activate a contract and
            initiate work, a payment of 25% of the total amount is to be sent to
            Vanguard Solution by the service seeker; this will serve as the
            deposit for the work to be completed. The amount is to be considered
            in offsetting payments by milestone, such that further payments will
            only be forthcoming upon exhaustion of the earlier deposited amount.
          </p>
        </Div>
        <Footer>
          {" "}
          <p> Copyright {"\u00A9"} 2019 Vanguard Solution </p>
        </Footer>
      </React.Fragment>
    );
  }
}
