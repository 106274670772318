import React, { Component } from "react";
import styled from "styled-components";
import {
  Button,
  HeroButtonHolder,
  FullButton,
  Footer,
  Hr,
} from "../assets/Common";
import img from "../../assets/desk.jpg";
import HeroTemplate from "./HeroTemplate";
import { Link as ScrollLink } from "react-scroll";
import axios from "axios";
import Modal from "react-modal";


import { Redirect } from "react-router-dom";


import "react-confirm-alert/src/react-confirm-alert.css";
import TranscriptForm from "./TranscriptForm";

const objectToFormData = require("object-to-formdata");

axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "XCSRF-TOKEN";
Modal.setAppElement("#root");

export class Transcription extends Component {
  constructor() {
    super();

    this.state = {
      id: "",
      name: "",
      email: "",
      title: "",
      instructions: "",
      duration: "",
      file: "",

      percentage: 0,
      redirectToSuccess: 0,
      redirectToFail: 0,
    };

  }


  closeModal() {
    this.setState({ modalIsOpen: false, percentage: 0, duration: 0 });
    document.getElementById("selectFile").value = "";
  }


  render() {
    return (
      <div style={{ position: "relative" }} className="Transcription">
        <HeroTemplate
          title="Unbeatable quality, at an unbeatable price."
          subtitle="48 hour turnaround. $0.7/min standard charge. 98% accuracy. All guaranteed."
          backgroundImagePath={img}
        >
          <HeroButtonHolder>
            <ScrollLink to="form">
              <Button>Get started</Button>
            </ScrollLink>
          </HeroButtonHolder>
        </HeroTemplate>

        <TranscriptForm></TranscriptForm>
        <Footer>
          {" "}
          <p>
            {" "}
            Copyright {"\u00A9"} 2019 Vanguard Solution |
            paper@vanguardswift.com
          </p>
        </Footer>

        {this.state.redirectToSuccess ? <Redirect to="/success" /> : <div />}
        {this.state.redirectToFail ? <Redirect to="/fail" /> : <div />}
      </div>
    );
  }
}


export default Transcription;
