import React, { Component } from "react";
import styled from "styled-components";
import uuid from "uuid";
import { PayPalButton } from "react-paypal-button-v2";
import { Hr, FullButton } from "../assets/Common";
import { confirmAlert } from "react-confirm-alert";
import axios from "axios";
const objectToFormData = require("object-to-formdata");

class TranscriptForm extends Component {
  constructor() {
    super();

    this.state = {
      id: "",
      name: "",
      email: "",
      title: "",
      instructions: "",
      duration: "",
      file: "",

      percentage: 0,
      redirectToSuccess: 0,
      redirectToFail: 0,
    };
  }
  render() {
    return (
      <form
        encType="multipart/form-data"
        action=""
        id="form"
        onSubmit={this.submitForm}
      >
        <h2>Transcript information</h2>
        <Hr />
        <div className="inputs">
          <div>
            <label htmlFor="">Name:</label>
            <input
              placeholder="Name"
              required
              name="name"
              type="text"
              maxLength="60"
              value={this.state.name}
              onChange={this.onChange}
            />
          </div>
          <div>
            <label htmlFor="">Email:</label>
            <input
              placeholder="Email"
              required
              name="email"
              type="Email"
              maxLength="60"
              onChange={this.onChange}
            />
          </div>
          <div>
            <label htmlFor="" required>
              Topic:
            </label>
            <input
              placeholder="Topic"
              required
              type="Title"
              name="title"
              maxLength="200"
              onChange={this.onChange}
            />
          </div>
          <div>
            <label htmlFor="">Instructions:</label>
            <input
              placeholder="Instructions"
              required
              maxLength="300"
              name="instructions"
              onChange={this.onChange}
            />
          </div>
          <div>
            <label htmlFor="selectFile">Audio / Video: </label>
            <input
              required
              type="file"
              accept=".avi, .mp3, .mp4, .mpeg, .ogg, .amr, .aac, .wav, .3gp, .3gpp"
              id="selectFile"
              onChange={this.getFile}
              name="file"
            />
          </div>
        </div>
        <BottomDiv duration={this.state.duration}>
          <Filler percentage={this.state.percentage} />
          <audio onCanPlayThrough={this.getDuration} id="audio" />
          <CostingDiv>
            {" "}
            <div>
              <p>Duration:</p>
              <p
                style={{
                  color: "var(--theme-color)",
                  fontWeight: "bold",
                  fontSize: "1.5em",
                  textAlign: "left",
                }}
              >
                {" "}
                {Math.round(this.state.duration / 60) +
                  " minutes " +
                  Math.round(this.state.duration % 60) +
                  " seconds."}
              </p>
            </div>
            <div>
              <p>Cost:</p>
              <p
                style={{
                  color: "green",
                  fontWeight: "bold",
                  fontSize: "1.5em",
                }}
              >
                ${(this.cost = ((this.state.duration / 60) * 0.7).toFixed(2))}
              </p>
            </div>
          </CostingDiv>
        </BottomDiv>
        <SubmitButton type="submit" duration={this.state.duration}>
          {/* {this.state.duration ? "Proceed to Checkout" : "Done"} */}
          Upload and Checkout{" "}
        </SubmitButton>
        <div className="paypal-container collapsed">
          <PayPalButton
            createOrder={(data, actions) => {
              this.setState({ loaderIsActive: false });
              return actions.order.create({
                purchase_units: [
                  {
                    amount: {
                      currency_code: "USD",
                      value: this.cost,
                    },
                  },
                ],
              });
            }}
            onSuccess={(details, data) => {
              this.closeModal();
              console.log(details);
              axios
                .patch(
                  "https://vanguardswift.com/api/" + this.state.id + "/",
                  objectToFormData({ orderId: details.id })
                )
                .then(() => {
                  console.log("PATCH request successful");
                  this.setState({ redirectToSuccess: 1, audioPosted: true });
                })
                .catch((e) => {
                    this.handleConnectionError(e);
                });
            }}
            onCancel={() => {
              this.setState({ loaderIsActive: false });
              this.closeModal();
            }}
            onError={(error) => {
              this.setState({ redirectToFail: 1 });
            }}
            options={{
              clientId:
                "AQw8DvMIVJkXgklYh-_wTf8nw10WzxnMSRhlJB5jw5F-8B7Lv8HMzckeXb69QXCk6C4NCI6zAGC6va-s",
            }}
          />
        </div>
      </form>
    );
  }

  handleConnectionError = (e) => {
    confirmAlert({
      title: e.name,
      message:
        e.message,
      buttons: [
        {
          label: "Okay",
        },
        {
          label: "Cancel",
        },
      ],
    });
    this.setState({ percentage: 0 });
  };
  obUrl = "";
  file = "";
  dur = "";
  getDuration = (e) => {
    this.dur = Math.round(e.currentTarget.duration);
    this.setState({ duration: this.dur });
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  getFile = (e) => {
    this.file = e.currentTarget.files[0];
    this.setState({ file: this.file });
    this.setState({ percentage: 0 });
    if (
      this.file.name.match(/\.(avi|mp3|mp4|mpeg|ogg|amr|aac|wav|3gp|3gpp)$/i)
    ) {
      this.obUrl = URL.createObjectURL(this.file);
      document.getElementById("audio").setAttribute("src", this.obUrl);
    }
  };

  config = {
    onUploadProgress: (progressEvent) => {
      this.setState({
        percentage:
          Math.round(progressEvent.loaded * 100) / progressEvent.total,
      });
    },
  };
  submitForm = (e) => {
    e.preventDefault();

    if (this.state.id) {
      const data = objectToFormData(this.state);
      axios
        .put(
          "https://vanguardswift.com/api/" + this.state.id + "/",
          data,
          this.config
        )
        .then(() => {
          
        })
        .catch((e) => {
            this.handleConnectionError(e);
          });
    } else {
      this.setState({ id: uuid.v4() }, () => {
        console.log(this.state);
        const data = objectToFormData(this.state);
        axios
          .post("https://vanguardswift.com/api/", data, this.config)
          .then(() => {
            
          })
          .catch((e) => {
            this.handleConnectionError(e);
            this.setState({ id: "" });
          });
      });
    }
    const inputs = document.getElementsByClassName("inputs")[0];
    inputs.classList.toggle("collapsed");

    const paypalContainer = document.getElementsByClassName(
      "paypal-container"
    )[0];
    paypalContainer.classList.toggle("collapsed");
  };
}

const SubmitButton = styled(FullButton)`
  margin: 0;
  width: 70%;
  align-self: center;
  margin-top: 20px;
  background-color: ${(props) =>
    props.duration ? "var(--theme-color)" : "grey"};
  border-color: ${(props) => (props.duration ? "var(--theme-color)" : "grey")};
  transition: all 0.2s ease-in;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const BottomDiv = styled.div`
  max-height: ${(props) => (props.duration ? "1000px" : 0)};
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  transition: all 0.1s ease-in;
`;

const CostingDiv = styled.div`
  /*display: ${(props) => (props.duration ? "block" : "block")};*/
  width: 100%;
  margin-top: 10px;
  align-self: flex-end;
  display: flex;
  text-align: left;
  justify-content: space-between;

  @media screen and (max-width:768px){
    flex-direction: column;
  }
`;

const Filler = (props) => {
  return (
    <div className="progress-bar">
      <div className="filler" style={{ width: `${props.percentage}%` }} />
    </div>
  );
};

export default TranscriptForm;
