import React from "react";
import styled from "styled-components";


const Hero = styled.div`
  background-image: url(${(props => props.backgroundImagePath)});
  background-size: cover;
  background-position: 30% 30%;
  position: relative;
  width: 100%;
  height: 92vh;
  display: flex;
  flex-direction: column;

  /* &::before {
    position: absolute;
    z-index: 1;
    width: 100%;
    content: "";
    height: 100%;
    background-color: rgba(255, 255, 255, 0);
  } */
`;

const HeroHeader = styled.h1`
  font-family: "Rufina", serif;
  font-weight: normal;
  font-size: 2.8em;
  display: block;
  padding-bottom: 15px;
  align-self: flex-start;
`;

const HeroSubHeader = styled.p`
  font-family: "Rufina", serif;
  color: black;
  font-size: 18px;
`;

const HeroContainer = styled.div`
  /* z-index: 1; */
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 60px;
  position: relative;
 

  @media screen and (max-width: 768px) {
    padding: 30px;
  }
`;

const HeroContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

export default function HeroTemplate(props) {
  return (
    <Hero backgroundImagePath = {props.backgroundImagePath}>
      <HeroContainer>
        <HeroHeader>{props.title}</HeroHeader>
        <HeroSubHeader>{props.subtitle}</HeroSubHeader>
        <HeroContent>{props.children}</HeroContent>
      </HeroContainer>
    </Hero>
  );
}
