import React, { Component } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";

export const ScrollButton = styled.div`
  width: 60px;
  height: 60px;
  background-color: var(--theme-color);
  position: fixed;
  right: 50px;
  color: white;
  font-size: 1.3em;
  text-align: center;
  vertical-align: middle;
  line-height: 60px;
  bottom: 50px;
  border-radius: 70px;
  z-index: 20;
  opacity: ${props => (props.show ? 1 : 0)};
  transition: opacity 0.4s, background-color 0.2s;
  &:hover{
    background-color:var(--theme-color-dark); 
  }
`;

export class ScrollToTop extends Component {
  state = {
    show: false
  };

  render() {
    window.onscroll = this.toggleScrollButton;
    return (
      <ScrollButton
        show={this.state.show}
        onClick={() => {
          window.scrollTo(0, 0);
        }}
      >
        <FontAwesomeIcon icon={faArrowUp} />
      </ScrollButton>
    );
  }

  toggleScrollButton = () => {
    if (
      document.body.scrollTop > 400 ||
      document.documentElement.scrollTop > 400
    ) {
      // document.getElementById("myBtn").style.display = "block";
      this.setState({ show: true });
    } else {
      // document.getElementById("myBtn").style.display = "none"
      this.setState({ show: false });
    }
  };
}

export default ScrollToTop;
