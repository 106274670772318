import React, { Component } from "react";
import HeroTemplate from "./HeroTemplate";
import ScrollToTop from "../assets/ScrollToTop";
import {
  Section,
  HeroButtonHolder,
  Img,
  FlexDiv,
  ImgContainer,
  Footer,
  FullButton
} from "../assets/Common";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Link as ScrollLink } from "react-scroll";

import handshakeImg from "../../assets/handshake.jpg";
import woodImg from "../../assets/stock.jpg";
import deskImg from "../../assets/desk.jpg";
import solarImg from "../../assets/solar.jpg";
import stocksImg from "../../assets/stock.jpg";
import bookImg from "../../assets/book.jpg";
import shipImg from "../../assets/ship.jpg";
import insuranceImg from "../../assets/insurance.jpg";

import nvivoImg from "../../assets/logos/nvivo.png";
import spssImg from "../../assets/logos/spss.png";
import xlstatImg from "../../assets/logos/xlstat.png";

/*GRIDS *******************************************************************************************/
const DualGrid = styled.div`
  display: grid;
  align-self: center;
  width: 100%;
  grid-gap: 80px;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "one two";
  justify-content: end;

  @media screen and (max-width: 1024px) {
    grid-gap: 20px;
    grid-template-columns: none;
    grid-template-areas:
      "one"
      "two";
  }
`;

const ToolsGrid = styled.div`
  display: grid;
  justify-content: center;
  grid-gap: 50px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: "one two three";

  @media screen and (max-width: 768px) {
    grid-template-columns: none;
    grid-template-areas:
      "one"
      "two"
      "three";
  }
`;

const QuadGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    "one two"
    "three four";
  grid-gap: 15px;

  @media screen and (max-width: 768px) {
    grid-template-columns: none;
    grid-template-areas:
      "one"
      "two"
      "three"
      "four";
  }
`;

const ToolLogo = styled.img`
  height: auto;
  width: 100%;
  max-width: 300px;
`;

const LoneP = styled.p`
  align-self: center;
  max-width: 500px;
  padding-bottom: 20px;
`;

export default class Research extends Component {
  constructor(props) {
    super(props);
    this.aboutUsRef = React.createRef();
  }

  render() {
    return (
      <div className="Research">
        <HeroTemplate
          title="Partner with a pro."
          subtitle="We leverage teamwork and experience to dramatically reduce the time it takes."
          backgroundImagePath={bookImg}
        >
          <HeroButtonHolder>
            <ScrollLink to="aboutUs">
              <FullButton>Learn More</FullButton>
            </ScrollLink>
          </HeroButtonHolder>
        </HeroTemplate>
        <Section
          id="aboutUs"
          title="About Us"
          className = "about-us"
          /*subtitle="We provide research assistance to SMEs."*/
        >
          <DualGrid>
            <Img
              src={handshakeImg}
              alt="Teamwork"
              style={{ gridArea: "one" }}
            />
            <p className = "page-description"
              style={{
                height: "300px",
                overflowWrap: "break-word ",
                gridArea: "two"
              }}
            >
              Think of us as your jogging partner, only, a jogging partner with
              marathon experience. We leverage a team featuring a statistician,
              data collection specialists and writers, to navigate the mucky
              waters of research. What does all this mean to you? Well, it means
              that we can answer whatever question you pose through exploring
              extant literature. We also, in the bid to answer your questions,
              employ statistical approaches that involve collection and analysis
              of accurate and verifiable data.
            </p>
          </DualGrid>
        </Section>

        <Section title="Some industries we've worked in">
          <div id="industries">
            <FlexDiv style={{gridArea: "one"}}>
              <ImgContainer>
                <Img src={solarImg} alt="Solar Panels on Roof" />
                <p>
                  <span>Renewable energy:</span> An example of work done in
                  renewable energy is research to establish the sources of
                  funding for renewable energy projects in Kenya. This was a
                  qualitative study, and data collected was based on interviews
                  with stakeholders. It was found that most banks in the country
                  were reluctant to offer loans to renewable energy companies
                  mainly because of high perceived risk. This resulted from
                  inadequate understanding of the renewable energy business.
                </p>
              </ImgContainer>
            </FlexDiv>
            <FlexDiv style={{gridArea: "two"}}>
              <ImgContainer>
                <Img src={stocksImg} alt="Desk" />
                <p>
                  <span>Finance:</span> In finance, the impact of financial
                  technology-based lenders on bank lending was studied. Findings
                  indicated that in Kenya, non-bank lenders, such as Branch and
                  Tala, had little impact on traditional banks’ market share.
                  This is because non-bank lenders have far fewer lending
                  restrictions than banks.
                </p>
              </ImgContainer>
            </FlexDiv>
            <FlexDiv style={{gridArea: "three"}}>
              <ImgContainer>
                <Img src={insuranceImg} alt="Desk" />
                <p>
                  <span>Insurance:</span> We have assessed the factors that
                  affect strategy implementation in an insurance firm. The
                  essence of the study was to highlight factors that presented
                  as bottlenecks in the strategy implementation process.
                </p>
              </ImgContainer>
            </FlexDiv>
            <FlexDiv style={{gridArea: "four"}}>
              <ImgContainer>
                <Img src={shipImg} alt="Desk" />
                <p>
                  <span>Shipping:</span> What are the factors that affect the
                  satisfaction of agents operating in the industry? Well, we can
                  tell you that the reliability of the service is certainly one
                  of the most important aspects. How do we know this? We have
                  data that proves it!
                </p>
              </ImgContainer>
            </FlexDiv>
          </div>
        </Section>

        <Section style={{ backgroundColor: "blue" }} title="Our Tools">
          <LoneP>
            In research, using the correct statistical tools is just as
            important and the research question itself. At Vanguard Swift, we
            take time with you understand research question, ultimately putting
            it down clearly in a concept paper. From here, we give you
            professional advice on the kind of questions to ask, the approach to
            take in data collection (including the best sources and optimal
            quantities of data) and the best analysis and presentation
            techniques. We make research what it is meant to be: a direct and
            seamless quest for the truth.{" "}
          </LoneP>
          <ToolsGrid>
            <ToolLogo
              style={{ gridArea: "one" }}
              src={nvivoImg}
              alt="NVivo Logo"
            />
            <ToolLogo
              style={{ gridArea: "two" }}
              src={spssImg}
              alt="SPSS Logo"
            />
            <ToolLogo
              style={{ gridArea: "three" }}
              src={xlstatImg}
              alt="XlStat Logo"
            />
          </ToolsGrid>
        </Section>

        <Section title="Other Services">
          <DualGrid>
            <FlexDiv>
              <p style={{ gridArea: "one" }}>
                Along the process of data collection and analysis, some services
                may be required, depending on the analysis approach employed.
                Some of these services are audio transcription (e.g. for
                interviews in qualitative analysis), switching between data
                formats (e.g. csv to excel), and data entry. We offer these
                services at an affordable price stipulated in the Price List.
              </p>

              <Link to="pricing">
                <FullButton style={{ alignSelf: "center", margin: "30px" }}>
                  View Price List
                </FullButton>
              </Link>
            </FlexDiv>
            <QuadGrid>
              <Img
                src={handshakeImg}
                alt="Teamwork"
                style={{ gridArea: "one" }}
              />
              <Img src={nvivoImg} alt="Teamwork" style={{ gridArea: "two" }} />
              <Img src={deskImg} alt="Teamwork" style={{ gridArea: "three" }} />
              <Img src={woodImg} alt="Teamwork" style={{ gridArea: "four" }} />
            </QuadGrid>
          </DualGrid>
        </Section>
        <ScrollToTop />
        <Footer>
          {" "}
          <p>
            {" "}
            Copyright {"\u00A9"} 2019 Vanguard Solution |
            paper@vanguardswift.com
          </p>
        </Footer>
      </div>
    );
  }
}
